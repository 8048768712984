<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
            @change="updatePerPage"
          />
          <!-- <b-button
            variant="primary"
            :to="{ name: 'drs-invoices-form', params: { id: 'create' } }"
          >
            {{ $t('invoice.add_invoice') }}
          </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search')"
              @keyup="searchQuotes"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="filterInvoices"
      responsive
      :fields="headers"
      primary-key="id"
      :sort-by.sync="sortBy"
      :per-page="0"
      show-empty
      :empty-text="$t('invoice.no_result')"
      :sort-desc.sync="isSortDirDesc"
      :busy="isBusy"
      :current-page="currentPage"
      class="position-relative rows-responsive"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <!-- Column: Id -->
      <template #head(budget_id)>
        <div
          class="mx-auto"
        >
          {{ $t('budget.code') }}
        </div>
      </template>

      <template #cell(budget_id)="data">
        <b-link
          :to="{
            name: 'drs-budgets-form',
            params: { id: data.item.budget_id },
          }"
          :class="data.item.status === 'CANCELLED' ? 'font-weight-bold text-danger' : 'font-weight-bold '"
        >
          {{ data.item.code }}
        </b-link>
      </template>

      <template #head(status)>
        {{ $t("budget.status") }}
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`invoice-row-${data.item.budget_id}`"
          size="32"
          :variant="
            `light-${
              resolveInvoiceStatusVariantAndIcon(data.item.status).variant
            }`
          "
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.budget_id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.status }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.budget_updated_date }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.customer_name }}
          </span>
          <small>{{ data.item.city }} - {{ data.item.cp }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.item.budget_date }}
        </span>
      </template>

      <!-- Column: Total -->
      <template #cell(total)="data">
        <span>{{ formatNumberEuro(data.item.total) }} €</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-row>
          <b-button
            :to="{
              name: 'drs-budgets-form',
              params: { id: data.item.budget_id },
            }"
            variant="success"
            class="btn-icon mrbt"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
            />
          </b-button>
          <b-button
            :to="{
              name: 'drs-budgets-preview',
              params: { id: data.item.budget_id },
            }"
            variant="info"
            class="btn-icon mrbt"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
            />
          </b-button>
          <b-button
            v-if="data.item.status === 'PENDING'"
            variant="danger"
            class="btn-icon"
            @click="confirmDeleteBudget(data.item.budget_id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
            />
          </b-button>
        </b-row>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >{{ $t("common.displaying") }}
            {{
              perPage * currentPage > totalItems
                ? totalItems
                : perPage * totalItems
            }}
            {{ $t("common.of") }} {{ totalItems }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-if="renderPagination"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import dbBudget from '@/models/budget'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      renderPagination: true,
      invoices: [],
      search: '',
      status: '',
      isBusy: false,
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalItems: null,
      headers: [],
      fields: [
        { key: 'status', sortable: false, class: 'Status' },
        { key: 'budget_id', sortable: false, class: this.$i18n.t('budget.code') },
        {
          key: 'client', label: 'budget.client', sortable: false, class: this.$i18n.t('budget.client'),
        },
        {
          key: 'total', label: 'budget.total', sortable: false, class: this.$i18n.t('budget.total'),
        },
        {
          key: 'issuedDate', label: 'invoice.budget_date', class: this.$i18n.t('invoice.budget_date'),
        },
        {
          key: 'actions', label: 'common.actions', class: this.$i18n.t('common.actions'),
        },
      ],
    }
  },
  computed: {
    filterInvoices() {
      return this.invoices.filter(
        i => i.customer_name.toLowerCase().includes(this.search.toLowerCase())
          || i.city.toLowerCase().includes(this.search.toLowerCase())
          || i.code.toLowerCase().includes(this.search.toLowerCase())
          || i.budget_date.toLowerCase().includes(this.search.toLowerCase())
          || i.status.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    filterInvoicesByStatus() {
      return this.invoices.filter(i => i.status.toLowerCase().includes(this.status.toLowerCase()))
    },
  },
  watch: {
    currentPage() {
      this.fetch(this.currentPage)
    },
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetch(1)
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  mounted() {
    this.fieldLanguage()
  },
  methods: {
    searchQuotes() {
      if (this.search.length > 2 || this.search.length === 0) this.fetch(1)
    },
    fieldLanguage() {
      this.headers = JSON.parse(JSON.stringify(this.fields))
      this.fields.forEach((header, index) => {
        if (header.label) {
          this.headers[index].label = `${this.$i18n.t(this.fields[index].label)}`
        }
      })
    },
    updatePerPage(size) {
      this.perPage = size
      this.fetch()
    },
    async fetch(page) {
      const self = this
      self.isBusy = true
      const response = await dbBudget.getAll({
        page, perPage: self.perPage, paginate: true, word: self.search,
      })
      self.invoices = response.data
      self.totalItems = response.total
      self.isBusy = false
      self.renderPagination = false
      self.$nextTick(() => {
        self.renderPagination = true
      })
    },
    confirmDeleteBudget(idBudget) {
      this.$bvModal
        .msgBoxConfirm(this.$t('budget.modal.delete_budget_body'), {
          title: this.$t('budget.modal.delete_budget_title'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('orders.modal.yes'),
          cancelTitle: this.$t('orders.modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) this.deleteBudget(idBudget)
        })
    },
    async deleteBudget(idBudget) {
      await dbBudget.deleteBudget(idBudget)
      this.fetch(1)
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = ['INVOICED', 'SENT', 'CREATED', 'PENDING', 'CANCELLED', 'ACCEPTED']

    const {
      fetchInvoices,
      tableColumns,
      currentPage,
      dataMeta,
      // searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      // statusFilter,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      currentPage,
      dataMeta,
      // searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      // statusFilter,
      refetchData,
      statusOptions,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>
<style>
  .Total {
    text-align: right;
  }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
